import { useContext, useEffect, useState } from "react";
import Context from "../../Context";

function Testimonial() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = useContext(Context);
  return isDesktop ? (
    <div className="px-[8%] grid grid-cols-2 gap-x-16 py-[5%]">
      <img
        className="h-full w-auto"
        src={data.testimonial.img}
        alt="Testimonial"
      />
      <div className="flex flex-col py-[7%]">
        <div className="text-left font-poppins-bold text-[2.5vw] py-5">
          {data.testimonial.title}
        </div>
        <div className="text-left font-poppins-medium text-[1.5vw] max-[960px]:text-[1.75vw] py-5">
          {data.testimonial.description}
        </div>
        <div className="w-full bg-[#001515] p-[3vw] grid grid-cols-[0.35fr_0.05fr_0.6fr] gap-x-5 mt-5 rounded-xl text-left">
          <div className="flex flex-col gap-y-2 items-start">
            <img
              className="h-[5vw] my-2 object-contain"
              src={data.testimonial.profile.img}
              alt="profile"
            />
            <div className="text-[#05F7F7] text-[1.5vw] max-[960px]:text-[1.75vw] font-poppins-medium">
              {data.testimonial.profile.name}
            </div>
            <div className="text-[#4B6161] text-[1vw] max-[960px]:text-[1.25vw] font-poppins-regular">
              {data.testimonial.profile.position}
            </div>
            <div className="text-[#4B6161] text-[1vw] max-[960px]:text-[1.25vw] font-poppins-regular">
              {data.testimonial.profile.company}
            </div>
          </div>
          <div className="h-full w-[3px] bg-[#405858]"></div>
          <div className="text-white font-poppins-semi-bold-italic text-[1.25vw] max-[960px]:text-[1.75vw] flex justify-center items-center">
            {data.testimonial.profile.message}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="px-[8%] flex flex-col py-[7%] my-20">
      <div className="font-poppins-bold text-[7vw] py-2 text-left">
        {data.testimonial.title}
      </div>
      <div className="font-poppins-medium text-[4.5vw] text-[#525252] py-2 text-left">
        {data.testimonial.description}
      </div>
      <img
        className="h-auto w-full my-10"
        src={data.testimonial.mobileImg}
        alt="Testimonial"
      />
      <div className="w-full bg-[#001515] py-[10vw] px-[5vw] grid grid-cols-[0.35fr_0.05fr_0.6fr] gap-x-1 mt-5 rounded-xl text-left">
        <div className="flex flex-col items-start gap-y-1">
          <img
            className="h-[10vw] my-2 object-contain"
            src={data.testimonial.profile.img}
            alt="profile"
          />
          <div className="text-[#05F7F7] text-[3.5vw] font-poppins-medium">
            {data.testimonial.profile.name}
          </div>
          <div className="text-[#4B6161] text-[2.5vw] font-poppins-regular">
            {data.testimonial.profile.position}
          </div>
          <div className="text-[#4B6161] text-[2.5vw] font-poppins-regular">
            {data.testimonial.profile.company}
          </div>
        </div>
        <div className=" w-[3px] bg-[#405858]"></div>
        <div className="text-white font-poppins-italic text-[3vw] flex justify-center items-center">
          {data.testimonial.profile.message}
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
