import React, { useState, useEffect, useRef } from 'react';
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import IMAGES from '../../static/images';
import './styles.css'

function handleButtonClick({ target }: any) {
  const buttons = document.querySelectorAll('.navbar-btn');
  buttons.forEach((button) => {
    button.classList.remove("navbar-btn-selected");
    button.classList.remove("solutions-btn-selected");
  });
  target.classList.add("navbar-btn-selected");
}

function Navbar() {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 960)
  const [solutionsBtn, setSolutionsBtn] = useState(false)
  const solutionItems = [
    {
      menuOption: (
      <div className="grid grid-cols-[0.8fr_0.2fr] place-items-start gap-x-2">
        <div className="flex flex-row gap-x-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <rect width="30" height="30" rx="7" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.041 11.7169C15.7918 11.7169 13.9655 13.5432 13.9655 15.7925C13.9655 17.8945 15.5608 19.6275 17.6054 19.8449C17.1933 20.4082 16.5281 20.7736 15.7768 20.7736H13.0748C11.8259 20.7736 10.8129 19.7625 10.8111 18.5131L10.7988 11.2677C10.7975 10.6668 11.0357 10.0899 11.4604 9.66425C11.8852 9.23904 12.4616 8.99994 13.063 8.99994C13.9139 8.99994 14.926 8.99994 15.7768 8.99994C17.0271 8.99994 18.041 10.0138 18.041 11.2641V11.7169Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5229 17.7651L22.5982 19.8404C22.8631 20.1058 22.8631 20.536 22.5982 20.8013C22.3328 21.0662 21.9026 21.0662 21.6373 20.8013L19.4628 18.6264C19.0357 18.8415 18.553 18.9624 18.0422 18.9624C16.2929 18.9624 14.8724 17.5418 14.8724 15.7925C14.8724 14.0432 16.2929 12.6227 18.0422 12.6227C19.7915 12.6227 21.2121 14.0432 21.2121 15.7925C21.2121 16.5379 20.9544 17.2235 20.5229 17.7651Z" fill="white"/>
          </svg>
          <div className="font-poppins-regular text-[1vw] place-self-center">
            Research
          </div>
        </div>
        <div className="text-black text-[1vw] place-self-center">
          <i className="fa-solid fa-arrow-right"></i>
        </div>
      </div>
    ),
      url: "https://research.onfinance.ai/"
    }, 
    {
      menuOption: (
        <div className="grid grid-cols-[0.8fr_0.2fr] place-items-start gap-x-2">
          <div className="flex flex-row gap-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <rect width="30" height="30" rx="7" fill="black"/>
              <path d="M20.5974 15.5676C20.5974 15.547 20.5974 15.5058 20.5974 15.4852C20.5974 13.6323 19.5375 12.0264 18.0178 11.2441C18.0178 11.1823 18.0178 11.1205 18.0178 11.0588C18.0178 9.92641 17.1179 8.99994 16.0181 8.99994C14.9182 8.99994 14.0184 9.92641 14.0184 11.0588C14.0184 11.1205 14.0184 11.1823 14.0184 11.2441C12.4986 12.0058 11.4388 13.6323 11.4388 15.4852C11.4388 15.5058 11.4388 15.547 11.4388 15.5676C10.6189 15.8146 9.99902 16.597 9.99902 17.5441C9.99902 18.6764 10.8989 19.6029 11.9987 19.6029C12.4386 19.6029 12.8586 19.4588 13.1985 19.1911C13.9784 19.8088 14.9582 20.1999 15.9981 20.1999C17.0379 20.1999 18.0178 19.8294 18.7976 19.1911C19.1376 19.4382 19.5375 19.6029 19.9974 19.6029C21.0973 19.6029 21.9971 18.6764 21.9971 17.5441C22.0371 16.6176 21.4372 15.8146 20.5974 15.5676ZM13.8184 18.4705C13.9584 18.1823 14.0384 17.8735 14.0384 17.5441C14.0384 16.5352 13.3185 15.6911 12.3786 15.5264C12.3786 15.5058 12.3786 15.5058 12.3786 15.4852C12.3786 14.0441 13.1785 12.7882 14.3383 12.1499C14.6983 12.7264 15.3182 13.1176 16.0381 13.1176C16.758 13.1176 17.3779 12.7264 17.7378 12.1499C18.8976 12.7882 19.6975 14.0441 19.6975 15.4852C19.6975 15.5058 19.6975 15.5058 19.6975 15.5264C18.7576 15.6911 18.0378 16.5352 18.0378 17.5441C18.0378 17.8735 18.1177 18.1823 18.2577 18.4705C17.6378 18.9646 16.8779 19.2529 16.0381 19.2529C15.1982 19.2529 14.4383 18.9441 13.8184 18.4705Z" fill="white"/>
            </svg>
            <div className="font-poppins-regular text-[1vw] w-max place-self-center">
              Sales Team
            </div>
          </div>
          <div className="text-black text-[1vw] place-self-center">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div>
      ),
      url: "https://sales.onfinance.ai"
    },
    {
      menuOption: (
        <div className="grid grid-cols-[0.8fr_0.2fr] place-items-start gap-x-2">
          <div className="flex flex-row gap-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <rect width="30" height="30" rx="7" fill="black"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.199 19.8002H20.799V15.0002H11.199V19.8002ZM9.99902 21.0002H21.999V13.8002H9.99902V21.0002ZM12.999 17.4002H18.999V16.2002H12.999V17.4002ZM11.199 12.6002H20.799V11.4002H11.199V12.6002ZM12.399 10.2002H19.599V9.00018H12.399V10.2002Z" fill="white"/>
            </svg>
            <div className="font-poppins-regular text-[1vw] w-max place-self-center">
              Customer Success
            </div>
          </div>
          <div className="text-black text-[1vw] place-self-center">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div>
      ),
      url: "https://cs.onfinance.ai/"
    }
  ];

  const handleSolutionsClick = ({ target }: any) => {
    if (!solutionsBtn) {
      const buttons = document.querySelectorAll('.navbar-btn');
      buttons.forEach((button) => {
        button.classList.remove("navbar-btn-selected");
        button.classList.remove("solutions-btn-selected");
      });
      target.classList.add("solutions-btn-selected");
    } else {
      target.classList.remove("solutions-btn-selected")
    }
    setSolutionsBtn(!solutionsBtn)
  }

  const handleResize = () => {
    setIsDesktop(window.innerWidth > 960);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let menuRef = useRef<HTMLElement>(null)

  useEffect(() => {
    let handler = (event: any) => {
      if ((menuRef.current !== null) && (!(menuRef.current.contains(event.target)))) {
        const elements = document.querySelectorAll('.solutions-btn');
        elements.forEach((element) => {
          element.classList.remove("solutions-btn-selected");
        });
        setSolutionsBtn(false)
      }
    }
    document.addEventListener("mousedown", handler)

    return () => {
      document.removeEventListener("mousedown", handler)
    }
  })

  return (
    <nav className="font-medium px-[5%] navbar">
      <div className="py-5 md:py-8">
        <div className="grid grid-flow-col">
          <div className="text-black">
            <a href="/">
              <div className="flex justify-start">
                <img className="h-10 w-10" src={IMAGES.onfinanceLogo} alt="Onfinance Logo" />
                <div className="text-xl pt-1 pl-2">OnFinance</div>
              </div>
            </a>
          </div>
          {
            isDesktop ?
            <div className="flex justify-end text-xl">
              <a href="#neogpt">
                <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Why NeoGPT?</button>
              </a>
              <a href="#feature">
                <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Features</button>
              </a>
              <a href="#pricing">
                <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Pricing</button>
              </a>
              <a href="#blogs">
                <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Blogs</button>
              </a>
              <div className="py-2 mx-4 navbar-btn solutions-btn text-center flex flex-row gap-x-1 justify-center items-center cursor-pointer" onClick={handleSolutionsClick} ref={menuRef as React.RefObject<HTMLDivElement>}>
                Solutions
                {!solutionsBtn && <svg className="-z-10" xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.93306 4.81694C4.17714 5.06102 4.57286 5.06102 4.81694 4.81694L8.56694 1.06694C8.81102 0.822864 8.81102 0.427136 8.56694 0.183058C8.32286 -0.0610189 7.92714 -0.0610189 7.68306 0.183058L4.375 3.49112L1.06694 0.183058C0.822864 -0.0610189 0.427136 -0.0610189 0.183058 0.183058C-0.0610194 0.427136 -0.0610194 0.822864 0.183058 1.06694L3.93306 4.81694Z" fill="#789393"/>
                </svg>}
              </div>
              <div className={`solutions-content ${solutionsBtn ? "active" : "inactive"}`}>
                {solutionItems.map((item, index) => (
                  <a className="block py-3 px-1" key={index} href={item.url} onClick={() => {setSolutionsBtn(!solutionsBtn)}} target="blank">
                    {item.menuOption}
                  </a>
                ))}
              </div>
              <a href="#contact">
                <button className="py-2 mx-4 navbar-btn text-center" onClick={handleButtonClick}>Contact</button>
              </a>              
            </div> : <DropdownMenu/>
          }
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
