import { useContext, useEffect, useState } from "react";
import Context from "../../Context";

function Insights() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = useContext(Context);

  return isDesktop ? (
    <div className="px-[8%] grid grid-cols-2 gap-x-16 my-16">
      <div className="flex flex-col items-start py-[5%] text-left">
        <div className="font-poppins-bold text-[2.5vw] py-5">
          {data.insights.heading}
        </div>
        {data.insights.insightPoints.map((insight, idx) => (
          <div className="flex flex-row my-5" key={idx}>
            <img className="mr-2 h-[5vw]" src={insight.icon} alt="Insight 1" />
            <div className="font-poppins-semi-bold text-[1.5vw] max-[960px]:text-[1.75vw] flex justify-center items-center">
              {insight.description}
            </div>
          </div>
        ))}
      </div>
      <img
        className="place-self-end h-full w-auto"
        src={data.insights.img}
        alt="insights"
      />
    </div>
  ) : (
    <div className="px-[8%] flex flex-col gap-y-2 my-10">
      <div className="font-poppins-bold text-[7vw] py-3 text-left">
        {data.insights.heading}
      </div>
      {data.insights.insightPoints.map((insight, idx) => (
        <div className="flex flex-row my-2" key={idx}>
          <img className="mr-2 h-[14vw]" src={insight.icon} alt="Insight 1" />
          <div className="font-poppins-semi-bold text-[4.5vw] text-[#525252] text-left flex items-center">
            {insight.description}
          </div>
        </div>
      ))}
      <img
        className="my-5 place-self-end w-auto"
        src={data.insights.mobileImg}
        alt="insights"
      />
    </div>
  );
}

export default Insights;
