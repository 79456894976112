import React, { useEffect, useState } from "react";
import "./styles.css"

type FeatureCardProps = {
  img: string;
  title: string;
  description: string;
}

function FeatureCard({ img, title, description }: FeatureCardProps) {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    isDesktop
    ?
    <div className="bg-white p-[2.5vw] flex flex-col items-start justify-between text-left rounded-xl feature-card">
      <img className="h-[5vw] mb-[1vw]" src={img} alt={`${title} feature`} />
      <div className="font-poppins-bold text-[1.65vw] max-[960px]:text-[2vw] my-[1vw]">
        {title}
      </div>
      <div className="font-poppins-medium text-[#757878] text-[1.25vw] max-[960px]:text-[1.69vw] my-[1vw]">
        {description}
      </div>
    </div>
    :
    <div className="bg-white p-[2.5vw] flex flex-col gap-y-2 text-left">
      <div className="flex flex-row gap-x-5 items-center my-3">
        <img className="h-[8vw] w-auto" src={img} alt={`${title} feature`} />
        <div className="font-poppins-bold text-[4.5vw] my-[1vw]">
          {title}
        </div>
      </div>
      <div className="font-poppins-regular text-[#757878] text-[3.5vw]">
        {description}
      </div>
    </div>
  )
}

export default FeatureCard