import { useEffect } from "react";

function RedirectToNotion() {
  useEffect(() => {
    window.location.href = "https://cat-tempo-c01.notion.site/OnFinance-AI-announces-the-launch-of-the-World-s-First-Generative-AI-Powered-Equity-Research-Agent-5b9cab9edda44479b7aa9ecbb5e78c95";
  }, []);

  return null;
}

export default RedirectToNotion;
