import AnimateOnScroll from "../AnimateOnScroll/AnimateOnScroll";
import BackedBy from "../BackedBy/BackedBy";
import Blog from "../Blog/Blog";
import Examples from "../Examples/Examples";
import Features from "../Features/Features";
import Footer from "../Footer/Footer";
import Insights from "../Insights/Insights";
import Introduction from "../Introduction/Introduction";
import MediaFeatured from "../MediaFeatured/MediaFeatured";
import Pricing from "../Pricing/Pricing";
import ProductHuntSection from "../ProductHunt/ProductHunt";
import Testimonial from "../Testimonial/Testimonial";

import "./styles.css";
// import BackedBy from "../BackedBy/BackedBy"

function Body() {
  return (
    <div id="neogpt" className="pt-[5%] body">
      {/* <Introduction />
      <Features />
      <Testimonial />
      <Insights />
      <Examples />
      <Pricing />
      <BackedBy />
      <Blog />
      <Footer /> */}



      <AnimateOnScroll>
        <Introduction />
      </AnimateOnScroll>
      <AnimateOnScroll>
        <Features />
      </AnimateOnScroll>
      <AnimateOnScroll>
        <BackedBy />
      </AnimateOnScroll>
      <AnimateOnScroll>
        <ProductHuntSection />
      </AnimateOnScroll>
      <AnimateOnScroll>
        <MediaFeatured />
      </AnimateOnScroll>
      <AnimateOnScroll>
        <Testimonial />
      </AnimateOnScroll>
      <AnimateOnScroll>
        <Insights />
      </AnimateOnScroll>
      <AnimateOnScroll>
        <Examples />
      </AnimateOnScroll>
      <AnimateOnScroll>
        <Pricing />
      </AnimateOnScroll>

      {/* <AnimateOnScroll>
        <Blog />
      </AnimateOnScroll> */}
      <AnimateOnScroll>
        <Footer />
      </AnimateOnScroll>
    </div>
  );
}

export default Body;
