import { useEffect, useState } from "react";

import "./styles.css";

type PricingCardProps = {
  icon: string;
  title: string;
  price?: string;
  term?: string;
  features: string[];
  buttonText: string;
  bgColor?: string;
  limitedTimeOfferCardBgColor?: string;
  termColor?: string;
  subjectText: string;
};

function PricingCard(props: {
  pricingCards: PricingCardProps;
  borderRadius: string;
}) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isDesktop ? (
    <div
      className={`bg-white p-10 flex flex-col items-start ${props.borderRadius}`}
    >
      <div className="py-3">
        <img src={props.pricingCards.icon} alt={props.pricingCards.title} />
      </div>
      <div className="py-3 max-[960px]:py-2 font-poppins-extra-bold text-[1.5vw] max-[960px]:text-[2vw]">
        {props.pricingCards.title}
      </div>
      {props.pricingCards.price !== undefined && (
        <div className="py-3 max-[960px]:py-2 flex flex-row items-center">
          <div className="font-poppins-extra-bold text-[1.5vw] max-[960px]:text-[2vw]">
            {props.pricingCards.price}
          </div>
          <div className="font-poppins-medium text-[1.25vw] max-[960px]:text-[1.5vw] text-[#898989]">
            {props.pricingCards?.term}
          </div>
        </div>
      )}
      <div className="w-full h-[1px] bg-[#DDD] my-3"></div>
      {props.pricingCards.features.map((feature, idx) => (
        <div
          key={idx}
          className="text-left text-black font-poppins-bold text-[1vw] max-[960px]:text-[1.75vw] py-3.5"
        >
          <i className="fa-solid fa-circle-check mr-2"></i>
          {feature}
        </div>
      ))}
      <a
        className="w-full"
        href={`mailto:anuj@onfinance.in?subject=${encodeURIComponent(
          props.pricingCards.subjectText
        )}`}
      >
        <button className="w-full font-poppins-semi-bold px-7 py-5 mr-2 mt-5 text-[1.25vw] max-[960px]:text-[1.35vw] pricing-btn">
          {props.pricingCards.buttonText}
        </button>
      </a>
    </div>
  ) : (
    <div
      className={`bg-white p-4 flex flex-col items-start ${props.borderRadius}`}
    >
      <div className="py-2">
        <img src={props.pricingCards.icon} alt={props.pricingCards.title} />
      </div>
      <div className="py-2 font-poppins-extra-bold text-[5vw]">
        {props.pricingCards.title}
      </div>
      {props.pricingCards.price !== undefined && (
        <div className="py-2 flex flex-row items-center">
          <div className="font-poppins-extra-bold text-[4.5vw]">
            {props.pricingCards.price}
          </div>
          <div className="font-poppins-medium text-[4.25vw] text-[#898989]">
            {props.pricingCards?.term}
          </div>
        </div>
      )}
      <div className="w-full h-[1px] bg-[#DDD] my-3"></div>
      {props.pricingCards.features.map((feature, idx) => (
        <div
          key={idx}
          className="text-left text-black font-poppins-bold text-[3vw] py-2"
        >
          <i className="fa-solid fa-circle-check mr-2"></i>
          {feature}
        </div>
      ))}
      <a
        className="w-full"
        href={`mailto:anuj@onfinance.in?subject=${encodeURIComponent(
          props.pricingCards.subjectText
        )}`}
      >
        <button className="w-full font-poppins-semi-bold px-7 py-3 mt-5 text-[3.5vw] pricing-btn">
          {props.pricingCards.buttonText}
        </button>
      </a>
    </div>
  );
}

export default PricingCard;
