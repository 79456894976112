import React, { useEffect, useState } from "react";

type ExampleCardProps = {
  buttonText: string;
  img: any;
  mobileImg: any;
  heading: string;
  description: string;
  linkText: string;
  linkUrl: string;
}

function ExampleCard(props: { examples: ExampleCardProps, idx?: number, setBtn?: React.Dispatch<React.SetStateAction<number>> }) {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.idx !== undefined) {
      const targetElement = document.getElementById(`example${props.idx}`) as Element; // Replace with your element's ID

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (props.setBtn !== undefined) {
              props.setBtn(props.idx!)
            }
          }
        });
      });
      observer.observe(targetElement!);
    }
  }, [])

  return (
    isDesktop
    ?
    <div className="grid grid-cols-2">
      <img className="w-[90%] h-auto" src={props.examples.img} alt={props.examples.heading} />
      <div className="py-[5%] flex flex-col text-left my-auto">
        <div className="text-[#878787] font-poppins-medium text-[1.35vw] max-[960px]:text-[1.75vw] py-3">
          {props.examples.heading}
        </div>
        <div className="font-poppins-semi-bold text-[1.3vw] max-[960px]:text-[1.5vw] py-3">
          {props.examples.description}
        </div>
        <a href={props.examples.linkUrl}>
          <div className="text-[#097575] font-poppins-semi-bold text-[1.25vw] max-[960px]:text-[1.5vw] py-3">
            {`${props.examples.linkText} >`}
          </div>
        </a>
      </div>
    </div>
    :
    <div className="flex flex-col text-center bg-white relative" style={{ boxShadow: "0px 0px 71px 0px rgba(0, 0, 0, 0.05)" }}>
      <div className="bg-[#006A6A] text-white text-[5vw] h-[13vw] flex justify-center items-center rounded-t-xl">
        {props.examples.buttonText}
      </div>
      <img className="w-full h-auto" src={props.examples.mobileImg} alt={props.examples.heading} />
      <div className="text-[#878787] font-poppins-medium text-[4vw] my-5 px-3 text-left">
        {props.examples.heading}
      </div>
      <div id={`example${props.idx}`} className="flex justify-center items-center absolute top-[50%] left-[50%]"></div>
      <div className="font-poppins-semi-bold text-[3vw] text-left px-3 my-5">
        {props.examples.description}
      </div>
      <a href={props.examples.linkUrl}>
        <div className="text-[#097575] bg-[#DADADA] rounded-b-xl font-poppins-semi-bold text-[3.5vw] py-3">
          {`${props.examples.linkText} >`}
        </div>
      </a>
    </div>
  )
}

export default ExampleCard