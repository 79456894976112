import React, { useEffect, useRef, ReactNode } from 'react';
import "./styles.css"

interface AnimateOnScrollProps {
  children: ReactNode;
}

const AnimateOnScroll: React.FC<AnimateOnScrollProps> = ({ children }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let observerRefValue: any = null
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && elementRef.current) {
          elementRef.current.classList.add('animate'); // Add your animation class
          observer.unobserve(elementRef.current);
        }
      });
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
      observerRefValue = elementRef.current
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, []);

  return <div ref={elementRef}>{children}</div>;
};

export default AnimateOnScroll;
