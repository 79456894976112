import React, { createContext } from 'react';
import constants from "./constants";

const Context = createContext(constants);

export const Provider = ({ children }: any) => {
  return (
    <Context.Provider value={constants}>
      {children}
    </Context.Provider>
  );
};

export default Context;
