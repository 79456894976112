import { useContext, useEffect, useState } from "react";
import Context from "../../Context";
import IMAGES from "../../static/images";
import Companies from "../Companies/Companies";

import "./styles.css";

function Introduction() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const watchDemo = () => {
    window.open("https://youtu.be/dgXzB99fZtU?si=Z5ltGSsPPijFEDyw", "blank");
  };

  const data = useContext(Context);

  return isDesktop ? (
    <div id="introduction" className="flex flex-col">
      <div className="px-[8%] grid grid-cols-2 gap-x-10 justify-between relative">
        <div className="flex flex-col justify-between text-left">
          <div className="inline-flex flex-col items-start">
            <div className="text-[#789393] font-poppins-semi-bold text-[1.25vw] max-[960px]:text-[1.5vw]">
              NeoGPT<sup>TM</sup>
            </div>
            <div className="font-poppins-extra-bold text-[2.5vw] max-[960px]:text-[3vw] my-2 text-[#138181]">
              {data.intro.header1}
            </div>
            <div className="font-poppins-extra-bold items-center text-[2.5vw] max-[960px]:text-[3vw] my-2">
              {data.intro.header2}
            </div>
            <div className="text-[#455A5A] text-[1.25vw] max-[960px]:text-[1.5vw] font-poppins-semi-bold my-2">
              {data.intro.description}
              <div className="flex flex-row items-center ml-[-0.7vw]">
                <img className="w-15 h-16" src="/soc.png" alt="SOC certified" />
                <img className="w-10 h-9 ml-1" src="/Subject.png" alt="ISO certified" />
              </div>
            </div>
          </div>
          <div>
            <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
              <button className="font-poppins-semi-bold px-[1.25vw] py-[1.25vw] mr-2 text-[1.25vw] max-[960px]:text-[1.5vw] free-trial-btn">
                {data.getFreeTrialButtonText}
              </button>
            </a>
            <button
              className="font-poppins-semi-bold px-[1.25vw] py-[1.25vw] mx-2 text-[1.25vw] max-[960px]:text-[1.5vw] watch-demo-btn"
              onClick={watchDemo}
            >
              <i className="fa-solid fa-circle-play pr-1 watch-demo-icon"></i>
              {data.watchDemoButtonText}
            </button>
          </div>
        </div>
        <div
          className="place-self-end"
          style={{
            boxShadow: "0px 0px 70px 0px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
          }}
        >
          <img src={IMAGES.introPic} alt="Introduction" />
        </div>
      </div>
      <Companies />
    </div>
  ) : (
    <div className="flex flex-col text-left pt-[18vw] mb-20">
      <div className="flex flex-col gap-y-[28vw] justify-between px-[8%]">
        <div className="flex flex-col pr-[12%]">
          <div className="text-[#789393] font-poppins-semi-bold text-[3.5vw]">
            NeoGPT<sup>TM</sup>
          </div>
          <div className="font-poppins-extra-bold text-[8vw] text-[#138181]">
            {data.intro.header1}
          </div>
          <div className="font-poppins-extra-bold text-[8vw] mb-4">
            {data.intro.header2}
          </div>
          <div className="text-[#525252] text-[4vw] font-poppins-semi-bold my-2">
            {data.intro.description}
            <div className="flex flex-row justify-center items-center ml-[-0.7vw]">
                <img className="w-26 h-24" src="/soc.png" alt="SOC certified" />
                <img className="w-15 h-14 ml-1" src="/Subject.png" alt="ISO certified" />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-[2vw]">
          <a
            className="h-full"
            href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards"
          >
            <button className="font-poppins-semi-bold px-[1.25vw] py-[4vw] text-[4vw] w-full free-trial-btn">
              {data.getFreeTrialButtonText}
            </button>
          </a>

          <div className="h-full">
            <button
              className="font-poppins-semi-bold px-[1.25vw] py-[3.6vw] mx-2 text-[4vw] w-full watch-demo-btn"
              onClick={watchDemo}
            >
              <i className="fa-solid fa-circle-play pr-1 watch-demo-icon"></i>
              {data.watchDemoButtonText}
            </button>
          </div>
        </div>
      </div>
      <Companies />
    </div>
  );
}

export default Introduction;
