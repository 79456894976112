import { useContext, useEffect, useState } from "react";
import Context from "../../Context";

function BackedBy() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { backedBy } = useContext(Context);
  return isDesktop ? (
    <div className="flex flex-col gap-y-10 my-32 px-[8%]">
      <div className="font-poppins-semi-bold text-center text-[#777777] text-[1.6vw]">
        {backedBy.heading}
      </div>
      <div className="flex flex-row gap-x-[7vw] items-start justify-center">
        <div className="rounded-xl h-[6vw] w-[12vw] flex justify-center items-center">
          <a href={backedBy.links[0]}>
            <img
              src={backedBy.imgs[0]}
              alt="Logo 1"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.2",
                marginTop: "0.5vw",
              }}
            />
          </a>
        </div>
        {/* Repeat for each logo, adjusting height, width, and src as needed */}
        <div className="rounded-xl h-[6vw] w-[12vw] flex justify-center items-center">
          <a href={backedBy.links[1]}>
            {" "}
            <img
              src={backedBy.imgs[1]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.2",
              }}
            />{" "}
          </a>
        </div>
        {/* Repeat for each logo, adjusting height, width, and src as needed */}
        <div className="rounded-xl h-[6vw] w-[12vw] flex justify-center items-center">
          <a href={backedBy.links[2]}>
            {" "}
            <img
              src={backedBy.imgs[2]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.1",
                marginBottom: "0.5vw",
              }}
            />{" "}
          </a>
        </div>
        {/* Repeat for each logo, adjusting height, width, and src as needed */}
        <div className="rounded-xl h-[6vw] w-[12vw] flex justify-center items-center">
          <a href={backedBy.links[3]}>
            {" "}
            <img
              src={backedBy.imgs[3]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.2",
                marginTop: "0.1vw",
              }}
            />{" "}
          </a>
        </div>
        {/* Repeat for each logo, adjusting height, width, and src as needed */}
        <div className="rounded-xl h-[6vw] w-[12vw] flex justify-center items-center">
          <a href={backedBy.links[4]}>
            {" "}
            <img
              src={backedBy.imgs[4]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.2",
              }}
            />{" "}
          </a>
        </div>

        {/* Add more logos as needed */}
      </div>
      <div className="flex flex-row gap-x-[7vw] items-start justify-center">
        <div className="rounded-xl h-[6vw] w-[12vw] flex justify-center items-center">
          <a href={backedBy.links[5]}>
            <img
              src={backedBy.imgs[5]}
              alt="Logo 1"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "0.5",
                marginTop: "0.5vw",
              }}
            />
          </a>
        </div>
        {/* Repeat for each logo, adjusting height, width, and src as needed */}
        <div className="rounded-xl h-[6vw] w-[12vw] flex justify-center items-center">
          <a href={backedBy.links[6]}>
            {" "}
            <img
              src={backedBy.imgs[6]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.2",
              }}
            />{" "}
          </a>
        </div>
        {/* Repeat for each logo, adjusting height, width, and src as needed */}
        <div className="rounded-xl h-[6vw] w-[12vw] flex justify-center items-center">
          <a href={backedBy.links[7]}>
            {" "}
            <img
              src={backedBy.imgs[7]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "0.5",
                marginBottom: "0.5vw",
              }}
            />{" "}
          </a>
        </div>

        {/* Add more logos as needed */}
      </div>
    </div>
  ) : (
    // <div className="flex flex-col gap-y-5 my-10 px-[8%]">
    //   <div className="font-poppins-semi-bold text-center text-[#777777] text-[7vw]">
    //     {backedBy.heading}
    //   </div>
    //   <div className="flex flex-row flex-wrap gap-x-8 gap-y-3 justify-center">
    //     {backedBy.imgs.map((img, idx) => (
    //       <div
    //         key={idx}
    //         className="rounded-xl h-[15vw] w-[30vw] flex justify-center items-center"
    //       >
    //         <img
    //           src={img}
    //           alt={`Logo ${idx + 1}`}
    //           style={{
    //             height: "100%",
    //             width: "100%",
    //             objectFit: "scale-down",
    //             scale: "1.2",
    //           }}
    //         />
    //       </div>
    //     ))}
    //   </div>

    // </div>
    <div className="flex flex-col gap-y-5 my-10 px-[8%]">
      <div className="font-poppins-semi-bold text-center text-[#777777] text-[4vw]">
        {backedBy.heading}
      </div>
      <div className="flex flex-row flex-wrap gap-x-8 gap-y-4 justify-center items-center">
        <div className="rounded-xl h-[15vw] w-[25vw] flex justify-center items-center">
          <a href={backedBy.links[0]}>
            {" "}
            <img
              src={backedBy.imgs[0]}
              alt="Logo 1"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.1",
              }}
            />{" "}
          </a>
        </div>
        {/* Adjust height and width as needed for each logo */}
        <div className="rounded-xl h-[12vw] w-[25vw] flex justify-center items-center">
          <a href={backedBy.links[1]}>
            {" "}
            <img
              src={backedBy.imgs[1]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.1",
              }}
            />{" "}
          </a>
        </div>
        {/* Adjust height and width as needed for each logo */}
        <div className="rounded-xl h-[12vw] w-[25vw] flex justify-center items-center">
          <a href={backedBy.links[2]}>
            {" "}
            <img
              src={backedBy.imgs[2]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.1",
              }}
            />{" "}
          </a>
        </div>
        {/* Adjust height and width as needed for each logo */}
        <div className="rounded-xl h-[12vw] w-[25vw] flex justify-center items-center">
          <a href={backedBy.links[3]}>
            {" "}
            <img
              src={backedBy.imgs[3]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.1",
                marginTop: "1vw",
              }}
            />
          </a>
        </div>
        {/* Adjust height and width as needed for each logo */}
        <div className="rounded-xl h-[12vw] w-[25vw] flex justify-center items-center">
          <a href={backedBy.links[4]}>
            {" "}
            <img
              src={backedBy.imgs[4]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "1.2",
              }}
            />
          </a>
        </div>

        {/* Adjust height and width as needed for each logo */}
        <div className="rounded-xl h-[12vw] w-[25vw] flex justify-center items-center">
          <a href={backedBy.links[5]}>
            {" "}
            <img
              src={backedBy.imgs[5]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "0.5",
                marginTop: "1vw",
              }}
            />
          </a>
        </div>
        {/* Adjust height and width as needed for each logo */}
        <div className="rounded-xl h-[12vw] w-[25vw] flex justify-center items-center">
          <a href={backedBy.links[6]}>
            {" "}
            <img
              src={backedBy.imgs[6]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "0.9",
                marginTop: "1vw",
              }}
            />
          </a>
        </div>
        {/* Adjust height and width as needed for each logo */}
        <div className="rounded-xl h-[12vw] w-[25vw] flex justify-center items-center">
          <a href={backedBy.links[7]}>
            {" "}
            <img
              src={backedBy.imgs[7]}
              alt="Logo 2"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                scale: "0.6",
                marginTop: "1vw",
              }}
            />
          </a>
        </div>

        {/* Add more logos with custom sizes */}
      </div>
    </div>
  );
}

export default BackedBy;

// import { useContext } from "react";
// import Context from "../../Context";
// import "./styles.css";

// function BackedBy() {
//   const { backedBy } = useContext(Context);

//   return (
//     <div className="backed-by-container">
//       <div className="font-poppins-semi-bold text-center backed-by-heading">
//         {backedBy.heading}
//       </div>
//       <div className="backed-by-logos">
//         {backedBy.imgs.map((img, index) => (
//           <div key={index} className="logo-container">
//             <img
//               src={img}
//               alt={`Logo ${index + 1}`}
//               className="logo-image"
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default BackedBy;
