import { useEffect, useState } from "react";

type CentralPricingCardProps = {
  icon: string;
  title: string;
  price?: string;
  term?: string;
  features: string[];
  buttonText: string;
  bgColor?: string;
  limitedTimeOfferCardBgColor?: string;
  termColor?: string;
  subjectText: string;
};

function CentralPricingCard(props: {
  pricingCards: CentralPricingCardProps;
  borderRadius: string;
}) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isDesktop ? (
    <div
      className={`py-10 flex flex-col items-start ${props.borderRadius}`}
      style={{ backgroundColor: props.pricingCards.bgColor }}
    >
      <div className="py-3 w-full grid grid-cols-2">
        <img
          className="px-[2.5vw]"
          src={props.pricingCards.icon}
          alt={props.pricingCards.title}
        />
        <div
          className="p-2 w-fit h-fit text-white font-poppins-regular place-self-end max-[960px]:text-[1vw]"
          style={{ background: props.pricingCards.limitedTimeOfferCardBgColor }}
        >
          Limited time offer
        </div>
      </div>
      <div className="py-3 px-10 font-poppins-extra-bold text-[1.5vw] max-[960px]:text-[2vw] text-white">
        {props.pricingCards.title}
      </div>
      {props.pricingCards.price !== undefined && (
        <div className="py-3 max-[960px]:py-2 px-10 flex flex-col">
          <div className="py-3 max-[960px]:py-2 flex flex-row">
            <div
              className="font-poppins-bold text-[1.5vw] max-[960px]:text-[2.25vw] mr-2"
              style={{ color: props.pricingCards.termColor }}
            >
              <s>$999</s>
            </div>
            <div className="font-poppins-bold text-[1.5vw] max-[960px]:text-[2.25vw] text-[#00FFFF]">
              {props.pricingCards.price}
            </div>
            <div
              className="font-poppins-medium text-[1.25vw] max-[960px]:text-[2.25vw] my-1"
              style={{ color: props.pricingCards.termColor }}
            >
              {props.pricingCards?.term}
            </div>
          </div>
          {/* <div
            className="font-poppins-medium text-[1.25vw] max-[960px]:text-[2vw] my-2"
            style={{ color: props.pricingCards.termColor }}
          >
            {props.pricingCards?.term}
          </div> */}
        </div>
      )}
      <div className="px-10 w-full h-[1px] bg-[#343434] my-3"></div>
      {props.pricingCards.features.map((feature, idx) => (
        <div
          key={idx}
          className="text-left text-white font-poppins-bold text-[1vw] max-[960px]:text-[1.75vw] py-4 px-10"
        >
          <i className="fa-solid fa-circle-check mr-2"></i>
          {feature}
        </div>
      ))}
      <a
        className="w-full"
        href={`mailto:anuj@onfinance.in?subject=${encodeURIComponent(
          props.pricingCards.subjectText
        )}`}
      >
        <div className="px-10 w-full">
          <button className="w-full font-poppins-semi-bold px-7 py-5 mt-5 text-[1.25vw] max-[960px]:text-[1.75vw] central-pricing-btn">
            {props.pricingCards.buttonText}
          </button>
        </div>
      </a>
    </div>
  ) : (
    <div
      className={`py-5 flex flex-col items-start ${props.borderRadius}`}
      style={{ backgroundColor: props.pricingCards.bgColor }}
    >
      <div className="w-full grid grid-cols-2">
        <img
          className="px-4"
          src={props.pricingCards.icon}
          alt={props.pricingCards.title}
        />
        <div
          className="p-2 w-fit h-fit text-white font-poppins-regular place-self-end text-[3vw]"
          style={{ background: props.pricingCards.limitedTimeOfferCardBgColor }}
        >
          Limited time offer
        </div>
      </div>
      <div className="py-2 px-4 font-poppins-extra-bold text-[5vw] text-white">
        {props.pricingCards.title}
      </div>
      {props.pricingCards.price !== undefined && (
        <div className="py-2 px-4 flex flex-col">
          <div className="flex flex-row">
            <div
              className="font-poppins-bold text-[4.5vw] mr-2"
              style={{ color: props.pricingCards.termColor }}
            >
              <s>$999</s>
            </div>
            <div className="font-poppins-bold text-[4.5vw] text-[#00FFFF]">
              {props.pricingCards.price}
            </div>
          </div>
          <div
            className="font-poppins-medium text-[4.25vw] my-2"
            style={{ color: props.pricingCards.termColor }}
          >
            {props.pricingCards?.term}
          </div>
        </div>
      )}
      <div className="w-full h-[1px] bg-[#343434] my-3"></div>
      {props.pricingCards.features.map((feature, idx) => (
        <div
          key={idx}
          className="text-left text-white font-poppins-bold text-[3vw] py-2 px-4"
        >
          <i className="fa-solid fa-circle-check mr-2"></i>
          {feature}
        </div>
      ))}
      <a
        className="w-full"
        href={`mailto:anuj@onfinance.in?subject=${encodeURIComponent(
          props.pricingCards.subjectText
        )}`}
      >
        <div className="px-4 w-full">
          <button className="w-full font-poppins-semi-bold px-7 py-3 mt-5 text-[3.5vw] central-pricing-btn">
            {props.pricingCards.buttonText}
          </button>
        </div>
      </a>
    </div>
  );
}

export default CentralPricingCard;
