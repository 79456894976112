import React, { useContext, useEffect, useState } from "react";
import Context from "../../Context";
import FeatureCard from "../FeatureCard/FeatureCard";
import CenterFeatureCard from "../CenterFeatureCard/CenterFeatureCard";

function Features() {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data = useContext(Context)

  return (
    isDesktop
    ?
    <div id="feature" className="px-[8%] flex flex-col">
      <div className="font-poppins-bold text-[2.5vw] max-[960px]:text-[3.5vw] py-2">
        {data.features.header}
      </div>
      <div className="font-poppins-regular font-semibold text-[1.25vw] max-[960px]:text-[1.75vw] text-[#455A5A] py-2">
        {data.features.description}
      </div>
      <div className="grid grid-cols-3 gap-x-10 mt-16 mb-5">
        <div className="grid grid-rows-2 gap-y-10 justify-between">
          {data.features.leftSideCardImg.map((card, idx) => (
            <FeatureCard key={idx} { ...card } />
          ))}
        </div>
        <div className="h-full">
          <CenterFeatureCard { ...data.features.centerCard } />
        </div>
        <div className="grid grid-rows-2 gap-y-10 justify-between">
          {data.features.rightSideCardImg.map((card, idx) => (
            <FeatureCard key={idx} { ...card } />
          ))}
        </div>
      </div>
      <div className="w-[30%] mx-auto py-5">
        <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
          <button className="w-full font-poppins-semi-bold px-[2vw] py-[2vw] mr-2 text-[1.5vw] max-[960px]:text-[2vw] free-trial-btn">
            {data.getStartedNowButtonText}
          </button>
        </a>
      </div>
    </div>
    :
    <div id="feature" className="px-[8%] flex flex-col my-20">
      <div className="font-poppins-bold text-[7vw] py-1 text-left">
        {data.features.header}
      </div>
      <div className="font-poppins-regular font-medium text-[4vw] text-[#455A5A] py-1 mb-10 text-left">
        {data.features.description}
      </div>
      <div className="my-5">
        <CenterFeatureCard { ...data.features.centerCard } />
      </div>
      <div className="bg-white p-2 rounded-xl">
        {data.features.leftSideCardImg.map((card, idx) => (
          <div>
            <FeatureCard key={idx} { ...card } />
          </div>
        ))}
        {data.features.rightSideCardImg.map((card, idx) => (
          <div>
            <FeatureCard key={idx} { ...card } />
          </div>
        ))}
        <div className="w-full mx-auto py-5 pb-2">
          <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
            <button className="w-full font-poppins-semi-bold px-[2vw] py-[2vw] mr-2 text-[5vw] free-trial-btn">
              {data.getStartedNowButtonText}
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Features