import { useEffect, useState } from "react";

import IMAGES from "../../static/images";
import "./styles.css";

function Footer() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isDesktop ? (
    <div id="contact">
      <div className="w-full bg-black px-10 max-[960px]:px-10 py-20">
        <div className="flex flex-row gap-x-5 items-center text-left text-[2vw] max-[960px]:text-[3.5vw] mb-20">
          <img
            className="h-12 w-12"
            src={IMAGES.onfinanceLogo}
            alt="OnFinance Logo"
          />
          <div className="text-white font-poppins-semi-bold">OnFinance</div>
        </div>
        <div className="grid grid-cols-4 gap-x-5">
          <div className="flex flex-col gap-y-5 font-poppins-medium text-[0.8vw] max-[960px]:text-[1vw] text-left">
            <div className="text-[#454545] text-[1vw] max-[960px]:text-[1.75vw]">
              Write to us
            </div>
            <div className="flex flex-col">
              <div className="text-[#818181]">
                Mindweave transformers private limited
              </div>
              <div className="text-[#818181]">
                Reg Office: #677, 1st Floor, 27th main, 13th cross HSR L/o,
                Bangalore South, Bangalore KA 560102 IN
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-[#CDCDCD]">CIN : U62099KA2023PTC173821</div>
              <div className="text-[#CDCDCD]">Email: info@onfinance.in</div>
              <div className="text-[#CDCDCD]">Mob No. +91-7233089282</div>
            </div>
          </div>
          <div className="flex flex-row gap-x-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="163"
              viewBox="0 0 2 163"
              fill="none"
            >
              <path
                d="M1 162L1 0.999998"
                stroke="#242727"
                stroke-linecap="round"
              />
            </svg>
            <div className="flex flex-col gap-y-3 font-poppins-medium text-[1vw] max-[960px]:text-[1vw] items-start">
              <div className="text-[#454545]">Pages</div>
              <div className="text-white">
                <a href="#neogpt">NeoGPT</a>
              </div>
              <div className="text-white">
                <a href="#feature">Features</a>
              </div>
              <div className="text-white">
                <a href="#pricing">Pricing</a>
              </div>
              <div className="text-white">
                <a href="#contact">Contact</a>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-x-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="163"
              viewBox="0 0 2 163"
              fill="none"
            >
              <path
                d="M1 162L1 0.999998"
                stroke="#242727"
                stroke-linecap="round"
              />
            </svg>
            <div className="flex flex-col gap-y-3 font-poppins-medium text-[1vw] max-[960px]:text-[1vw] text-left">
              <div className="text-[#454545]">Solutions</div>
              <div className="text-white">
                <a href="https://research.onfinance.ai" target="blank">
                  Research
                </a>
              </div>
              <div className="text-white">
                <a href="https://sales.onfinance.ai" target="blank">
                  Sales Team
                </a>
              </div>
              <div className="text-white">
                <a href="https://cs.onfinance.ai" target="blank">
                  Customer Success
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-x-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="163"
              viewBox="0 0 2 163"
              fill="none"
            >
              <path
                d="M1 162L1 0.999998"
                stroke="#202020"
                stroke-linecap="round"
              />
            </svg>
            <div className="flex flex-col w-max gap-y-3 font-poppins-medium text-[1vw] max-[960px]:text-[1vw] items-start">
              <div className="text-[#454545]">Our Socials</div>
              <div className="p-5 bg-[#0D0D0D] rounded-2xl flex flex-col max-[800px]:gap-y-5 min-[800px]:grid min-[800px]:grid-cols-4 min-[800px]:gap-x-[1vw]">
                {/* <a href="" target="blank"><img className="h-[3vw] w-[3vw]" src={IMAGES.metaIcon} alt="Meta" /></a> */}
                <a
                  href="https://www.instagram.com/onfinance.ai/"
                  target="blank"
                >
                  <img
                    className="h-[3vw] w-[3vw]"
                    src={IMAGES.instagramIcon}
                    alt="Instagram"
                  />
                </a>
                <a href="https://twitter.com/OnFinance_ai" target="blank">
                  <img className="h-[3vw] w-[3vw]" src={IMAGES.xIcon} alt="X" />
                </a>
                <a href="https://www.youtube.com/@OnFinanceHQ" target="blank">
                  <img
                    className="h-[3vw] w-[3vw]"
                    src={IMAGES.youtubeIcon}
                    alt="YouTube"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/onfinanceofficial/"
                  target="blank"
                >
                  <img
                    className="h-[3vw] w-[3vw]"
                    src={IMAGES.linkedInIcon}
                    alt="LinkedIn"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#111111] flex justify-center font-poppins-regular text-[0.8vw] text-[#696969] pt-5">
        <a
          href="https://onfinance.github.io/policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
      <div className="w-full bg-[#111111] flex justify-center items-end font-poppins-regular text-[0.8vw] text-[#696969] pt-1 pb-5">
        Copyright OnFinance 2023-2024
      </div>
    </div>
  ) : (
    <div id="contact">
      <div className="w-full bg-black px-20 max-[960px]:px-10 py-10">
        <div className="flex flex-row gap-x-5 items-center text-left text-[5vw] mb-10">
          <img
            className="h-12 w-12"
            src={IMAGES.onfinanceLogo}
            alt="OnFinance Logo"
          />
          <div className="text-white font-poppins-semi-bold">OnFinance</div>
        </div>
        <div className="flex flex-col gap-y-3 font-poppins-medium text-[3.5vw] text-left my-10">
          <div className="text-[#454545]">Write to us</div>
          <div className="flex flex-col">
            <div className="text-[#818181]">
              Mindweave transformers private limited
            </div>
            <div className="text-[#818181]">
              Reg Office: #677, 1st Floor, 27th main, 13th cross HSR L/o,
              Bangalore South, Bangalore KA 560102 IN
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-[#CDCDCD]">CIN : U62099KA2023PTC173821</div>
            <div className="text-[#CDCDCD]">Email: info@onfinance.in</div>
            <div className="text-[#CDCDCD]">Mob No. +91-7233089282</div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-x-5">
          <div className="flex flex-col gap-y-3 font-poppins-medium text-[4vw] items-start">
            <div className="text-[#454545]">Pages</div>
            <div className="text-white">NeoGPT</div>
            <div className="text-white">Features</div>
            <div className="text-white">Pricing</div>
            <div className="text-white">Contact</div>
          </div>
          <div className="flex flex-row gap-x-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="163"
              viewBox="0 0 2 163"
              fill="none"
            >
              <path
                d="M1 162L1 0.999998"
                stroke="#242727"
                stroke-linecap="round"
              />
            </svg>
            <div className="flex flex-col gap-y-3 font-poppins-medium text-[4vw] text-left">
              <div className="text-[#454545]">Solutions</div>
              <div className="text-white">Research</div>
              <div className="text-white">Sales Team</div>
              <div className="text-white">Customer Success</div>
            </div>
          </div>
        </div>
        <div className="my-10 p-5 mb-0 bg-[#0D0D0D] rounded-2xl flex flex-row gap-x-3 w-fit mx-auto">
          {/* <a href="" target="blank"><img className="h-[12vw] w-[12vw]" src={IMAGES.metaIcon} alt="Meta" /></a> */}
          <a href="https://www.instagram.com/onfinance.ai/" target="blank">
            <img
              className="h-[12vw] w-[12vw]"
              src={IMAGES.instagramIcon}
              alt="Instagram"
            />
          </a>
          <a href="https://twitter.com/OnFinance_ai" target="blank">
            <img className="h-[12vw] w-[12vw]" src={IMAGES.xIcon} alt="X" />
          </a>
          <a href="https://www.youtube.com/@OnFinanceHQ" target="blank">
            <img
              className="h-[12vw] w-[12vw]"
              src={IMAGES.youtubeIcon}
              alt="YouTube"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/onfinanceofficial/"
            target="blank"
          >
            <img
              className="h-[12vw] w-[12vw]"
              src={IMAGES.linkedInIcon}
              alt="LinkedIn"
            />
          </a>
        </div>
      </div>
      <div className="w-full bg-[#111111] flex justify-center items-center font-poppins-regular text-[3vw] text-[#696969] pt-5">
        <a
          href="https://onfinance.github.io/policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
      <div className="w-full bg-[#111111] flex justify-center items-center font-poppins-regular text-[3vw] text-[#696969] pt-1 pb-10">
        Copyright OnFinance 2023-2024
      </div>
    </div>
  );
}

export default Footer;
