const IMAGES = {
  onfinanceLogo: require("./onfinance-logo.png"),
  introPic: require("./intro-pic-main.png"),
  edelweiss: require("./edelweiss.png"),
  nippon: require("./nippon.png"),
  motilalOswal: require("./motilal-oswal.png"),
  kotak: require("./kotak.png"),
  profile1: require("./profile-1.png"),
  profile2: require("./profile-2.png"),
  profile3: require("./profile-3.png"),
  linkedInIcon: require("./linkedin-icon.png"),
  xIcon: require("./x-icon.png"),
  instagramIcon: require("./instagram-icon.png"),
  youtubeIcon: require("./youtube-icon.png"),
  metaIcon: require("./meta-icon.png"),
  icici: require("./client-logo/icici-logo.png"),
  lv: require("./client-logo/lv-logo.png"),
  investindia: require("./client-logo/investindia-logo.png"),
  oister: require("./client-logo/oister-logo.png"),
  mosl: require("./client-logo/mosl-logo.png"),
  ey: require("./client-logo/ey-logo.png"),
  nse: require("./client-logo/nse.png"),
};

export default IMAGES;
