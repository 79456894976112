import React, { useState, useEffect, useRef } from 'react';
import './styles.css'

function DropdownMenu() {
  const [isOpen, setIsOpen] = useState(false);
  let menuRef = useRef<HTMLElement>(null)

  useEffect(() => {
    let handler = (event: any) => {
      if ((menuRef.current !== null) && (!(menuRef.current.contains(event.target)))) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", handler)

    return () => {
      document.removeEventListener("mousedown", handler)
    }
  })

  const dropdownItems = [
    {
      menuOption: "Why NeoGPT?",
      url: "/"
    },
    {
      menuOption: "Features",
      url: "#feature"
    },
    {
      menuOption: "Pricing",
      url: "#pricing"
    },
    {
      menuOption: "Blogs",
      url: "#blogs"
    },
    {
      menuOption: "Research",
      url: "https://research.onfinance.ai/"
    }, 
    {
      menuOption: "Sales",
      url: "https://sales.onfinance.ai"
    },
    {
      menuOption: "Support",
      url: "https://cs.onfinance.ai/"
    }, 
    {
      menuOption: "Contact",
      url: "#contact"
    }
  ];

  const getTarget = (menuOption: string) => {
    if (menuOption === "Research" || menuOption === "Sales" || menuOption === "Support") {
      return "blank"
    } else {
      return ""
    }
  }

  return (
    <div className="dropdown text-right justify-end rounded-lg relative bottom-[10px]" ref={menuRef as React.RefObject<HTMLDivElement>}>
      <button className="dropbtn" onClick={() => {setIsOpen(!isOpen)}}>
        <div className="menu-button">
          <div className="dash"></div>
          <div className="dash"></div>
          <div className="dash"></div>
        </div>
      </button>
      <div className={`dropdown-content ${isOpen ? "active" : "inactive"}`}>
        {dropdownItems.map((item, index) => (
          <a className="block py-3 px-1" key={index} href={item.url} onClick={() => {setIsOpen(!isOpen)}} target={getTarget(item.menuOption)}>
            {item.menuOption}
          </a>
        ))}
      </div>
    </div>
  );
};

export default DropdownMenu;
