import { useEffect, useState } from "react";
import IMAGES from "../../static/images";

function Companies() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [companyCard, setCompanyCard] = useState(0);
  useEffect(() => {
    setTimeout(() => setCompanyCard((companyCard + 1) % 4), 3000);
  }, [companyCard]);

  const getCompanyCard = () => {
    switch (companyCard) {
      case 0:
        return (
          <div>
            <img
              className="w-auto object-contain"
              src={IMAGES.oister}
              alt="OISTER"
            />
          </div>
        );
      case 1:
        return (
          <div>
            <img
              className="w-auto object-contain"
              src={IMAGES.mosl}
              alt="Motilal Oswal"
            />
          </div>
        );
      case 2:
        return (
          <div>
            <img
              className="w-auto object-contain"
              src={IMAGES.lv}
              alt="ICICI"
            />
          </div>
        );
      case 3:
        return (
          <div>
            <img
              className="w-auto object-contain"
              src={IMAGES.mosl}
              alt="MOSL"
            />
          </div>
        );
      default:
        return "";
    }
  };

  return isDesktop ? (
    <div className="px-[8%] grid grid-cols-[0.4fr_0.15fr_0.15fr_0.15fr_0.15fr] gap-x-10 justify-between my-[5%]">
      <div className="text-[#777777] text-[1vw] max-[960px]:text-[1.5vw] font-poppins-regular flex justify-center items-center text-left">
        OnFinance works with banks, asset managers, wealth professionals &
        insurance companies.
      </div>
      <div className="place-self-center">
        <img
          className="h-full w-auto object-contain max-h-[8.5vw] max-[960px]:max-h-[10vw]"
          src={IMAGES.oister}
          alt="ICICI"
        />
      </div>
      <div className="place-self-center">
        <img
          className="h-full w-auto object-contain max-h-[8.5vw] max-[960px]:max-h-[10vw]"
          src={IMAGES.nse}
          alt="Nippon"
        />
      </div>
      <div className="place-self-center">
        <img
          className="h-full w-auto object-contain max-h-[8.5vw] max-[960px]:max-h-[10vw]"
          src={IMAGES.lv}
          alt="Motilal Oswal"
        />
      </div>
      <div className="place-self-center">
        <img
          className="h-full w-auto object-contain max-h-[9.5vw] max-[960px]:max-h-[10vw]"
          src={IMAGES.mosl}
          alt="Kotak"
        />
      </div>
      {/* <div className="place-self-center">
        <img
          className="h-full w-auto object-contain max-h-[9.5vw] max-[960px]:max-h-[10vw]"
          src={IMAGES.nse}
          alt="NSE"
        />
      </div> */}
    </div>
  ) : (
    <div className="px-[8%] grid grid-cols-[0.7fr_0.3fr] gap-x-2 justify-between my-[10%]">
      <div className="text-[#777777] text-[1vw] max-[960px]:text-[1.5vw]  max-[800px]:text-[3vw]  font-poppins-regular flex justify-center items-center">
        OnFinance works with banks, asset managers, wealth professionals &
        insurance companies.
      </div>
      <div className="flex justify-center items-center my-[5%]">
        {getCompanyCard()}
      </div>
    </div>
  );
}

export default Companies;
