import React, { useEffect, useState } from "react";
import "./styles.css"

type CenterFeatureCardProps = {
  title: string;
  description: string;
  img: string;
  pointers: string[]
}

function CenterFeatureCard({ title, description, img, pointers }: CenterFeatureCardProps) {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    isDesktop
    ?
    <div className="bg-white p-[2.5vw] flex flex-col justify-between rounded-xl feature-card h-full">
      <div className="text-center text-[1.65vw] max-[960px]:text-[2vw] font-poppins-bold my-[1vw]">
        {title}
      </div>
      <div className="text-center text-[1.25vw] max-[960px]:text-[1.69vw] text-[#757878] font-poppins-medium my-[1vw]">
        {description}
      </div>
      <img className="my-[2vw] px-[2vw]" src={img} alt={`${title} feature`} />
      <div className="h-[2px] w-full bg-[#E9E9E9] my-[1.5vw]"></div>
      {pointers.map((pointer, idx) => (
        <div key={idx} className="text-left text-black font-poppins-bold text-[1.25vw] max-[960px]:text-[1.69vw] py-2">
          <i className="fa-solid fa-circle-check mr-[0.5vw]"></i>{pointer}
        </div>
      ))}
    </div>
    :
    <div className="bg-white p-[4vw] flex flex-col gap-y-1 rounded-xl feature-card">
      <div className="text-[5vw] font-poppins-bold my-[1vw] text-left">
        {title}
      </div>
      <div className="text-[3.5vw] font-poppins-medium my-[1vw] text-left">
        {description}
      </div>
      <img className="py-[10vw] px-[16vw] w-full h-auto" src={img} alt={`${title} feature`} />
      <div className="flex flex-row gap-x-3">
        <div className="h-full w-[2px] bg-[#E9E9E9] my-[1.5vw]"></div>
        <div className="flex flex-col justify-center gap-y-2 text-left text-[#696969] font-poppins-medium text-[4vw] py-2">
        {pointers.map((pointer, idx) => (
          <div key={idx}>
            <i className="fa-solid fa-circle-check mr-[3.5vw] text-[#097575]"></i>{pointer}
          </div>
        ))}
        </div>
      </div>
    </div>
  )
}

export default CenterFeatureCard