import { useContext } from "react";
import Context from "../../Context";
import "./styles.css";

function MovingTickerUpdate() {
  const { mediaFeatured } = useContext(Context);
  const url =
    "https://www.forbes.com/profile/onfinance-ai/?list=30under30-asia-finance-venture-capital/&sh=9b800ff65170";

  return (
    <div className="marquee">
      <div className="marquee__group">
        <a href={url} className="marquee__link">
          <span>
            ➺ Our founders have been featured on Forbes 30 under 30 Asia: Class
            of 2024. Look how we are looking to change the financial landscape
            with Generative AI. [Click to check out our media coverage]
          </span>
        </a>
        {/* You can add more <span> elements wrapped in <a> tags here if needed */}
      </div>
      <div className="marquee__group" aria-hidden="true">
        <a href={url} className="marquee__link">
          <span>
            ➺ Our founders have been featured on Forbes 30 under 30 Asia: Class
            of 2024. Look how we are looking to change the financial landscape
            with Generative AI. [Click to check out our media coverage]
          </span>
        </a>
        {/* You can add more <span> elements wrapped in <a> tags here if needed */}
      </div>
    </div>
  );
}

export default MovingTickerUpdate;
