import { useContext, useEffect, useState } from "react";
import Context from "../../Context";
import CentralPricingCard from "../CentralPricingCard/CentralPricingCard";
import PricingCard from "../PricingCard/PricingCard";

import "./styles.css";

function Pricing() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { pricing } = useContext(Context);
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };

  return isDesktop ? (
    <div id="pricing" className="bg-[#E8E8E8] w-full mt-24 px-[8%] py-14">
      <div className="flex flex-col justify-center items-center">
        <div className="font-poppins-bold text-[2.5vw] max-[960px]:text-[3vw] py-5">
          {pricing.heading}
        </div>
        <div className="font-poppins-medium text-[1.5vw] max-[960px]:text-[2vw] py-5">
          {pricing.description}
        </div>
        <div className="flex flex-row py-10 justify-center items-center relative">
          <div
            className={`text-[1vw] max-[960px]:text-[2vw] font-poppins-regular mx-3 ${
              !toggle ? "plan-selected" : "plan-deselected"
            }`}
          >
            Monthly
          </div>
          <div className="w-[9vw]">
            <label className="switch-desktop">
              <input type="checkbox" onClick={handleToggle} />
              <span className="slider-desktop"></span>
            </label>
          </div>
          <div
            className={`text-[1vw] max-[960px]:text-[2vw] font-poppins-regular mx-3 ${
              toggle ? "plan-selected" : "plan-deselected"
            }`}
          >
            Annually
          </div>
          <div className="flex flex-row absolute left-[22.5vw] max-[960px]:left-[32vw] top-[2vw]">
            <div>
              <div className="absolute left-[1.1vw] bg-[#E8E8E8] w-[6vw] h-[6.5vw] arrow"></div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8vw"
                height="8vw"
                viewBox="0 0 159 112"
                fill="none"
              >
                <path
                  d="M21.7283 15.6757C21.4288 16.1397 21.5621 16.7587 22.0261 17.0583L29.5875 21.9395C30.0515 22.239 30.6704 22.1057 30.97 21.6417C31.2695 21.1777 31.1362 20.5587 30.6722 20.2592L23.951 15.9203L28.2898 9.19912C28.5894 8.73512 28.4561 8.11615 27.9921 7.81662C27.528 7.51708 26.9091 7.6504 26.6096 8.11441L21.7283 15.6757ZM131.146 102.619C112.333 100.06 101.009 92.4058 93.3668 82.663C85.678 72.8603 81.6727 60.9007 77.6099 49.5557C73.581 38.3056 69.4718 27.5728 61.4661 20.7549C53.3715 13.8613 41.5051 11.1162 22.3579 15.2405L22.7791 17.1957C41.5886 13.1441 52.7262 15.9387 60.1694 22.2775C67.7015 28.6921 71.664 38.8846 75.727 50.23C79.756 61.4805 83.8629 73.7867 91.7931 83.8973C99.7703 94.0678 111.572 101.975 130.877 104.601L131.146 102.619Z"
                  fill="url(#paint0_linear_864_3755)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_864_3755"
                    x1="-84.121"
                    y1="-115.645"
                    x2="-171.029"
                    y2="-160.022"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop />
                    <stop offset="1" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className="text-[#999999] font-caveat-regular text-[1.25vw] max-[960px]:text-[1.5vw] flex justify-end items-end w-[8vw] relative top-[1vw] right-[1vw]">
              Save more with Annual plan !
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-x-10 justify-between overflow-hidden mt-10">
          <div className="h-fit  w-[50%]">
            <PricingCard
              pricingCards={pricing.startupPlan}
              borderRadius="rounded-r-lg"
            />
          </div>
          {!toggle && (
            <div
              className={`h-fit  w-[50%] ${
                !toggle ? "pricing-selected" : "pricing-deselected"
              }`}
            >
              <CentralPricingCard
                pricingCards={pricing.growthMonthlyPlan}
                borderRadius="rounded-lg"
              />
            </div>
          )}
          {toggle && (
            <div
              className={`h-fit  w-[50%] ${
                toggle ? "pricing-selected" : "pricing-deselected"
              }`}
            >
              <CentralPricingCard
                pricingCards={pricing.growthAnnualPlan}
                borderRadius="rounded-lg"
              />
            </div>
          )}
          {/* <div className="h-fit place-self-end w-[30%]">
            <PricingCard
              pricingCards={pricing.enterprisePlan}
              borderRadius="rounded-l-lg"
            />
          </div> */}
        </div>
      </div>
    </div>
  ) : (
    <div id="pricing" className="bg-[#E8E8E8] w-full mt-14 px-[8%] py-4 pb-10">
      <div className="flex flex-col">
        <div className="font-poppins-bold text-[7vw] py-3 text-left">
          {pricing.heading}
        </div>
        <div className="font-poppins-medium text-[3.5vw] py-3 text-left">
          {pricing.description}
        </div>
        <div className="flex flex-row py-5 justify-center items-center relative">
          <div
            className={`text-[4vw] font-poppins-regular mx-3 ${
              !toggle ? "plan-selected" : "plan-deselected"
            }`}
          >
            Monthly
          </div>
          <div className="w-[16vw]">
            <label className="switch">
              <input type="checkbox" onClick={handleToggle} />
              <span className="slider"></span>
            </label>
          </div>
          <div
            className={`text-[4vw] font-poppins-regular mx-3 ${
              toggle ? "plan-selected" : "plan-deselected"
            }`}
          >
            Annually
          </div>
        </div>
        <div className="flex flex-col gap-y-5 justify-between overflow-hidden">
          {!toggle && (
            <div
              className={`h-fit ${
                !toggle ? "pricing-selected" : "pricing-deselected"
              }`}
            >
              <CentralPricingCard
                pricingCards={pricing.growthMonthlyPlan}
                borderRadius="rounded-lg"
              />
            </div>
          )}
          {toggle && (
            <div
              className={`h-fit ${
                toggle ? "pricing-selected" : "pricing-deselected"
              }`}
            >
              <CentralPricingCard
                pricingCards={pricing.growthAnnualPlan}
                borderRadius="rounded-lg"
              />
            </div>
          )}
          <div className="h-fit">
            <PricingCard
              pricingCards={pricing.startupPlan}
              borderRadius="rounded-lg"
            />
          </div>
          {/* <div className="h-fit"><PricingCard pricingCards={pricing.enterprisePlan} borderRadius="rounded-lg" /></div> */}
        </div>
      </div>
    </div>
  );
}

export default Pricing;
