import "./styles.css";

function ProductHuntSection() {
  return (
    <div id="product-hunt" className="product-hunt-section py-10">
      <div className="product-hunt-content px-[8%] flex flex-col items-center text-center">
        <div className="product-hunt-text mb-4">
          <div className="font-poppins-extra-bold text-[2.5vw] max-[960px]:text-[3vw] my-2 text-[#138181]">
            NeoGPT Equity Analyst is on ProductHunt!
          </div>
          <div className="text-[#455A5A] text-[1.25vw] max-[960px]:text-[1.5vw] font-poppins-semi-bold my-2">
            Speed up workflows 100x using domain-specific models and customized workflows
          </div>
        </div>
        <div className="iframe-container">
          <iframe
            style={{ border: "none" }}
            src="https://cards.producthunt.com/cards/products/590266"
            width="700"
            height="525"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ProductHuntSection;
