import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import "./App.css";
import Body from "./components/Body/Body";
import MovingTickerUpdate from "./components/MovingTickerUpdate/MovingTickerUpdate";
import Navbar from "./components/Navbar/Navbar";
import RedirectToNotion from "./components/RedirectToNotion"; // Import the new component

function App() {
  useEffect(() => {
    // Initialize Google Analytics 4
    ReactGA.initialize("G-7N1B2X7EB1");
    ReactGA.send("pageview");
    // Alternatively, use ReactGA.pageview(window.location.pathname + window.location.search);

    // Dynamically load the Tidio chat script
    const script = document.createElement("script");
    script.src = "//code.tidio.co/lexoctaqzmmf7cpwzuw4iop71ljjcf4c.js";
    script.async = true;

    // Append the script to the <head>
    document.head.appendChild(script);

    // Optional: Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <MovingTickerUpdate />
        <Navbar />
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/equityresearchcopilot" element={<RedirectToNotion />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;