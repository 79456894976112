import { useContext } from "react";
import Context from "../../Context";
import "./styles.css";

function MediaFeatured() {
  const { mediaFeatured } = useContext(Context);

  return (
    <div className="flex flex-col gap-y-10 my-20 px-[1%]">
      <div className="font-poppins-semi-bold text-center text-[#777777] text-size">
        {mediaFeatured.heading}
      </div>
      <div className="flex flex-row gap-x-[1.5vw] gap-y-[2.5vw] items-start justify-center flex-wrap">
        {mediaFeatured.data.map((item, index) => (
          // Assuming each 'item' has a 'url' property for the link
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className="justify-center items-center w-[25vw] lg:w-[15vw] item"
          >
            <div className="image-container">
              <img
                src={item.img}
                alt={item.alt}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                  marginTop: "0.5vw",
                  padding: "2vw",
                }}
              />
            </div>
            <div className="hidden sm:block line"></div>
            <div className="hidden sm:block text-left text-ui">{item.desc}</div>
          </a>
        ))}
      </div>
      {/* <div className="font-poppins text-center text-[#777777] text-[1.1vw]">
        {mediaFeatured.subheading}
      </div> */}
    </div>
  );
}

export default MediaFeatured;
