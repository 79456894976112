import React, { useContext, useEffect, useState } from "react";
import Context from "../../Context";
import ExampleCard from "../ExampleCard/ExampleCard";

import "./styles.css"

function Examples() {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 800);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 800);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data = useContext(Context)
  const [btn, setBtn] = useState(0)
  const [isToggle, setIsToggle] = useState(true)

  useEffect(() => {
    if (isToggle && isDesktop) {
      const intervalId = setInterval(() => {
        setBtn((prevButton) => ((prevButton + 1) % 3));
      }, 10000);
      return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }
  }, [isToggle, isDesktop]);

  const getSectionComponent = () => {
    switch (btn) {
      case 0:
        return <div className="example-selected rounded-xl"><ExampleCard examples={data.examples.examples[0]} /></div>
      case 1:
        return <div className="example-selected rounded-xl"><ExampleCard examples={data.examples.examples[1]} /></div>
      case 2:
        return <div className="example-selected rounded-xl"><ExampleCard examples={data.examples.examples[2]} /></div>
      default:
        return null;
    }
  };

  const handleClick = (btnIdx: number) => {
    setBtn(btnIdx)
    const underline = document.getElementsByClassName('button-underline')
    const examples = document.getElementsByClassName('example-selected')
    for (let i = 0; i < underline.length; i++) {
      underline[i].classList.remove('button-underline');
    }
    for (let i = 0; i < examples.length; i++) {
      examples[i].classList.remove('example-selected');
    }
    setIsToggle(false)
  }
  
  return (
    isDesktop
    ?
    <div className="px-[8%] flex flex-col justify-center items-center py-[5%] pb-0">
      <div className="font-poppins-bold text-[2.5vw] max-[960px]:text-[3vw] py-3">
        {data.examples.heading}
      </div>
      <div className="font-poppins-semi-bold text-[1.5vw] max-[960px]:text-[1.75vw] text-[#455A5A] py-3 px-[10vw]">
        {data.examples.description}
      </div>
      <div className="my-20 mt-12 flex flex-row justify-center items-center">
        <button className={`relative font-poppins-regular rounded-lg px-8 py-4 mx-5 text-[1.25vw] max-[960px]:text-[1.75vw] btn ${btn === 0 ? "btn-selected" : ""}`} onClick={() => handleClick(0)}>
          {data.examples.examples[0].buttonText}
          <div className="rounded-b-lg button-underline absolute"></div>
        </button>
        <button className={`relative font-poppins-regular rounded-lg px-8 py-4 mx-5 text-[1.25vw] max-[960px]:text-[1.75vw] btn ${btn === 1 ? "btn-selected" : ""}`} onClick={() => handleClick(1)}>
          {data.examples.examples[1].buttonText}
          <div className="rounded-b-lg button-underline absolute"></div>
        </button>
        <button className={`relative font-poppins-regular rounded-lg px-8 py-4 mx-5 text-[1.25vw] max-[960px]:text-[1.75vw] btn ${btn === 2 ? "btn-selected" : ""}`} onClick={() => handleClick(2)}>
          {data.examples.examples[2].buttonText}
          <div className="rounded-b-lg button-underline absolute"></div>
        </button>
      </div>
      {getSectionComponent()}
    </div>
    :
    <div className="flex flex-col justify-center items-center py-[5%] pb-0 my-20">
      <div className="px-[8%] font-poppins-bold text-[7vw] py-2 text-left">
        {data.examples.heading}
      </div>
      <div className="px-[8%] font-poppins-medium text-[3.5vw] text-[#525252] py-2 text-left">
        {data.examples.description}
      </div>
      <div className="px-[8%] flex flex-row gap-x-2 my-10">
        <div className={btn === 0 ? "mobile-example-dash-active" : "mobile-example-dash-inactive"}></div>
        <div className={btn === 1 ? "mobile-example-dash-active" : "mobile-example-dash-inactive"}></div>
        <div className={btn === 2 ? "mobile-example-dash-active" : "mobile-example-dash-inactive"}></div>
      </div>
      <div className={`w-full my-5 overflow-hidden relative overflow-x-scroll blog-scroll`} style={{
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent"
      }}>
        <div className="flex" style={{ transition: "transform 0.3s ease-in-out" }}>
          <div className="mx-1 px-2 w-[100vw] flex-[0_0_90%] rounded-xl"><ExampleCard examples={data.examples.examples[0]} idx={0} setBtn={setBtn} /></div>
          <div className="mx-1 px-2 w-[100vw] flex-[0_0_90%] rounded-xl"><ExampleCard examples={data.examples.examples[1]} idx={1} setBtn={setBtn} /></div>
          <div className="mx-1 px-2 w-[100vw] flex-[0_0_90%] rounded-xl"><ExampleCard examples={data.examples.examples[2]} idx={2} setBtn={setBtn} /></div>
        </div>
      </div>
    </div>
  )
}

export default Examples