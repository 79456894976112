const constants = {
  getFreeTrialButtonText: "Get Free Trial",
  watchDemoButtonText: "Watch Demo",
  getStartedNowButtonText: "Get started Now",
  intro: {
    header1: "A Vertical Foundational LLM",
    header2: "for Finance and Banking",
    description: "NeoGPT excels at financial tasks to bring Gen AI in your internal teams securely & 12x faster than GPT-4.",
  },
  features: {
    header: "AI Copilots that accelerate work",
    description: "seamlessly reduce turnaround time for key business tasks with generative AI",
    leftSideCardImg: [
      {
        img: require("./static/images/enterprise-security.png"),
        title: "Enterprise Security",
        description: "Protect sensitive financial data about end-customers while ensuring regulatory compliance."
      },
      {
        img: require("./static/images/data-governance.png"),
        title: "Data Governance",
        description: "Streamline data management including consistent access control and source data quality."
      }
    ],
    centerCard: {
      title: "Grounded Answers",
      description: "Ensure accurate data foundation and enhance the reliability of responses and AI actions.",
      img: require("./static/images/grounded-answers.png"),
      pointers: [
        "Check numerical accuracy",
        "Analyze context correctly",
        "Clear data lineage"
      ]
    },
    rightSideCardImg: [
      {
        img: require("./static/images/inline.png"),
        title: "Inline",
        description: "Provides real-time guidance in the tools your team already uses to boost inference quality."
      },
      {
        img: require("./static/images/blazing-fast.png"),
        title: "Blazing Fast",
        description: "Speeds up operations, leading to reduced turnaround times and improved service delivery."
      }
    ]
  },
  testimonial: {
    img: require("./static/images/testimonial.png"),
    mobileImg: require("./static/images/mobile-testimonial.png"),
    title: "Supercharging Collaboration on Research Reports",
    description: "Ask questions, interpret from visualizations and chain results together into a report",
    profile: {
      img: require("./static/images/motilal.png"),
      name: "Parth Bhatt",
      position: "Manager",
      company: "Motilal Oswal Securities Limited",
      message: "“NeoGPT really boosts productivity of research and investment analysis tasks, especially in large teams.”"
    }
  },
  insights: {
    heading: "Leverage non-linear insights to your advantage",
    img: require("./static/images/insights.png"),
    mobileImg: require("./static/images/mobile-insights.png"),
    insightPoints: [
      {
        icon: require("./static/images/insight-1.png"),
        description: "Enriched with live public data"
      },
      {
        icon: require("./static/images/insight-2.png"),
        description: "Native understanding of your data catalog"
      },
      {
        icon: require("./static/images/insight-3.png"),
        description: "Equipped with specialized tools"
      }
    ]
  },
  examples: {
    heading: "Seamlessly deploy Gen AI with Enterprise Security",
    description: "NeoGPT is ready to assist your most critical business functions from report generations to customer interactions and customer profiling for upsell",
    examples: [
      {
        buttonText: "Task automations",
        img: require("./static/images/model-performance-example.png"),
        mobileImg: require("./static/images/mobile-task-automations-example.png"),
        heading: "Generative automations from conversational data",
        description: "Always honor customer promises by ensuring timely follow-up tasks and adhering to all SLAs. Eliminate the need for manual communication of relevant tasks to team members.",
        linkText: "NeoGPT for Relationship Managers",
        linkUrl: ""
      },
      {
        buttonText: "Data governance",
        img: require("./static/images/data-governance-example.png"),
        mobileImg: require("./static/images/mobile-data-governance-example.png"),
        heading: "Enterprise-grade security without any headaches",
        description: "Control which employees sees what enterprise dataset down to the last number and document. Seamlessly import existing permission controls from 12 different platforms & APIs.",
        linkText: "NeoGPT for Data & Compliance Teams",
        linkUrl: ""
      },
      {
        buttonText: "Model performance",
        img: require("./static/images/model-performance-example.png"),
        mobileImg: require("./static/images/mobile-model-performance-example.png"),
        heading: "Performance Benchmark vs Horizontal Gen AI Models",
        description: "Access the best Gen AI inference in financial services powered by NeoGPT and our innovations in financial data embedding, structured data retrieval, and human feedback mechanisms.",
        linkText: "NeoGPT for Enterprise Buyers",
        linkUrl: ""
      }
    ]
  },
  pricing: {
    heading: "Smart Pricing For Smart Financial Institution",
    description: "Explore our pricing plans that offer a range of features designed to give you a competitive edge with generative AI",
    startupPlan: {
      icon: require("./static/images/startup-plan-icon.png"),
      title: "Query Plan",
      price: "$0.2",
      term: "/query",
      features: [
        "OnFinance.ai number guarantee",
        "Public Data on Indian and US stocks",
        "All Zapier automation supported for actions",
        "1-click org chart import from Darwinbox"
      ],
      buttonText: "Start Now",
      subjectText: "Get Started with NeoGPT: Special Pricing for Startups!"
    },
    growthMonthlyPlan: {
      icon: require("./static/images/growth-plan-icon.png"),
      title: "Data Plan",
      price: "$499",
      term: "/TB/Month",
      features: [
        "Completely on-premises solution",
        "No query limits",
        "Connect enterprise datasets",
        "Granular Privacy Controls"
      ],
      buttonText: "Buy Now",
      bgColor: "#000000",
      limitedTimeOfferCardBgColor: "linear-gradient(265deg, #06F 45.67%, #75ACFF 151.03%)",
      termColor: "#989898",
      subjectText: "Ready to Grow? Explore NeoGPT's Dedicated Growth Plan!"
    },
    growthAnnualPlan: {
      icon: require("./static/images/growth-plan-icon.png"),
      title: "Data Plan",
      price: "$449",
      term: "/TB/Month",
      features: [
        "Completely on-premises solution",
        "No query limits",
        "Connect enterprise datasets",
        "Granular Privacy Controls"
      ],
      buttonText: "Buy Now",
      bgColor: "#001515",
      limitedTimeOfferCardBgColor: "linear-gradient(263deg, #05FFFF -41.8%, #75ACFF 150.83%)",
      termColor: "#989898",
      subjectText: "Ready to Grow? Explore NeoGPT's Dedicated Growth Plan!"
    },
    enterprisePlan: {
      icon: require("./static/images/enterprise-plan-icon.png"),
      title: "Enterprise Plan",
      features: [
        "Connect structured private data",
        "Data Governance",
        "Multi-lingual document support",
        "Hybrid deployment"
      ],
      buttonText: "Request Quote",
      subjectText: "Enterprise-Level Solutions with NeoGPT's Premium Plan!"
    }
  },
  backedBy: {
    heading: "Backed by the best in the industry",
    imgs: [
      // require("./static/images/backedBy-img.png"),
      // require("./static/images/backedBy-img.png"),
      // require("./static/images/backedBy-img.png"),
      require("./static/images/backedby/snv_website.png"),
      require("./static/images/backedby/lv-full-blue.png"),
      require("./static/images/backedby/ian_website.png"),
      require("./static/images/backedby/ssv.png"),
      require("./static/images/backedby/kunal.png"),
      require("./static/images/backedby/T-Hub_Logo.png"),
      require("./static/images/backedby/JioGenNext_Logo.png"),
      require("./static/images/backedby/ifsca_logo.png"),


    ],

    links: [
      "https://silverneedle.vc/",
      "https://www.letsventure.com/",
      "https://iangroup.vc/",
      "https://www.ssv.fund/",
      "https://in.linkedin.com/in/kunalshah1",
      "https://t-hub.co/",
      "https://www.jiogennext.com/",
      "https://www.ifsca.gov.in/"
    ]
  },
  mediaFeatured: {
    heading: "Featured in 15+ reputable news sources",
    subheading: " ",
    data: [
      //want iamge and data and link
      {
        img: require("./static/images/featurednews/yourstory.png"),
        alt: "yourstory",
        link: "https://yourstory.com/2023/11/startup-news-and-updates-daily-roundup-november-7-2023",
        desc: "OnFinance.ai raises $1.05m to productionize their AI copilots for BFSI"
      },
      {
        img: require("./static/images/featurednews/cnbc_transparent_logo.png"),
        alt: "CNBC TV 18",
        link: "https://www.cnbctv18.com/photos/business/forbes-30-under-30-asia-indian-entrepreneurs[…]yanan-blackstone-aniket-damle-yashvardhan-kanoi-19413891.htm",
        desc: "The Srivastava duo's OnFinance AI has introduced NeoGPT, Vertical LLM for BFSI."
      },
      {
        img: require("./static/images/featurednews/microsoft_transparent_logo.png"),
        alt: "Microsoft",
        link: "https://www.microsoft.com/en-in/aifirstmovers/onfinance",
        desc: "Recognized by Microsoft as a distinguished \"AI First Mover\" across India & SEA."
      },

      {
        img: require("./static/images/featurednews/finexp.png"),
        alt: "Financial Express",
        link: "https://www.financialexpress.com/business/digital-transformation-onfinance-ai-clocks-105-million-through-a-seed-funding-round-3299676/",
        desc: "BFSI can now convert their unstructured data into AI copilots with NeoGPT"
      },
      {
        img: require("./static/images/featurednews/Forbes logo.png"),
        alt: "Forbes",
        link: "https://www.forbes.com/profile/onfinance-ai/?list=30under30-asia-finance-venture-capital/&sh=4fd6abe86517",
        desc: "Featured in prestigious Forbes Asia's 30 under 30: 2024 class of emerging leaders."
      },
      // {
      //   img: require("./static/images/featurednews/isn.png"),
      //   alt: "IndianSTartupNews",
      //   link: "https://indianstartupnews.com/funding/genai-saas-solution-startup-onfinance-ai-raises-seed-funding-1688045",
      //   desc: "OnFinance aims to bring generative AI capabilities to the private cloud with NeoGPT"
      // },

      // {
      //   img: require("./static/images/featurednews/vccircle.png"),
      //   alt: "VCCircle",
      //   link: "https://www.vccircle.com/sequretekvaaree-onfinance-raise-early-stage-funding",
      //   desc: "OnFinance raises early stage funding to bring Generative AI to the financial sector"
      // },
      // {
      //   img: require("./static/images/featurednews/outlook.png"),
      //   alt: "OutlookIndia",
      //   link: "https://startup.outlookindia.com/sector/saas/onfinance-ai-raises-1-05-million-in-seed-funding-round-led-by-silverneedle-ventures-ian-news-9808",
      //   desc: "OnFinance.ai eyes the US market with their latest funding round"
      // },
      // {
      //   img: require("./static/images/featurednews/Bessemer Logo.jpg"),
      //   alt: "Bessemer",
      //   link: "https://www.bvp.com/atlas/rise-of-saas-in-india-2023",
      //   desc: "OnFinance.ai eyes the US market with their latest funding round"
      // },
      // {
      //   img: require("./static/images/featurednews/entrackr.png"),
      //   alt: "Entraker",
      //   link: "https://entrackr.com/2023/11/onfinance-ai-raises-1-05-mn-in-seed-round/",
      //   desc: "OnFinance.ai raises $1.05m to productionize their AI copilots for BFSI"
      // },


    ]
  },
  blog: {
    heading: "Learn how to best use NeoGPT",
    description: "Using case studies, technical documents, and best practices from the team",
    btn1: "Case Studies",
    btn2: "Data Security",
    btn3: "Team Leads",
    btn4: "Generative AI"
  }
}

export default constants